import React from "react"
import { graphql } from "gatsby"
import Layout from "@components/layout"
import ResourceHeader from "@components/resources/header"
import PropTypes from "prop-types"
import Flex from "@components/elements/flex"
import Grid from "@components/elements/grid"
import { useTheme } from "styled-components"
import Image from "@components/image"
import { FloatingImage } from "@components/common/images"
import { HTMLWrapper } from "@components/common/html-wrapper"

const AnalyticsTemplate = ({ data }) => {
  const analytics = data.analytics
  const { shadow } = useTheme()

  return (
    <Layout
      image={analytics.hero}
      fullWidth={true}
      body={analytics.body}
      date_created={analytics.date_created}
      date_updated={analytics.date_updated}
      seo={analytics.seo}
    >
      <ResourceHeader
        name={analytics.h1}
        subtitle={analytics.subtitle}
        image={analytics.hero}
        newCta
        shadow={false}
        kpi
        ctaCategory={`${analytics.name} Analytics`}
        links={[
          {
            text: "Resources",
            url: "/resources",
          },
          {
            text: "Analytics",
            url: "/resources/analytics",
          },
          {
            text: analytics.category.name,
            url: `/resources/analytics/${analytics.category.slug}`,
          },
          {
            text: analytics.name,
          },
        ]}
      />

      <Flex gap="8rem" margin="4rem 0">
        <HTMLWrapper
          textContainer
          html={analytics.body}
          images={analytics.body_images}
        />
        <Grid container columns="1fr 1fr" columnsMd="1fr" gap="4rem">
          <HTMLWrapper
            html={analytics.in_app_description}
            images={analytics.in_app_description_images}
          />
          <FloatingImage>
            <Image
              radius={1}
              borderStyle={{
                shadow: shadow.default,
              }}
              file={analytics.in_app_image}
            />
          </FloatingImage>
        </Grid>

        <Grid container columns="1fr 1fr" columnsMd="1fr" gap="4rem">
          <HTMLWrapper
            html={analytics.tool_description}
            images={analytics.tool_description_images}
          />
          <FloatingImage>
            <Image
              radius={1}
              borderStyle={{
                shadow: shadow.default,
              }}
              file={analytics.tool_image}
            />
          </FloatingImage>
        </Grid>

        <HTMLWrapper
          textContainer
          html={analytics.eop_body}
          images={analytics.eop_body_images}
        />
      </Flex>
    </Layout>
  )
}

AnalyticsTemplate.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
}

export default AnalyticsTemplate

export const pageQuery = graphql`
  query AnalyticsPageQuery($id: String!) {
    analytics(id: { eq: $id }) {
      category {
        slug
        name
      }
      slug
      hero {
        cdn
        placeholder
        title
      }
      h1
      name
      subtitle
      body
      body_images {
        cdn
        placeholder
        title
      }
      in_app_description
      in_app_description_images {
        cdn
        placeholder
        title
      }
      in_app_image {
        cdn
        placeholder
        title
      }
      tool_description
      tool_description_images {
        cdn
        placeholder
        title
      }
      tool_image {
        cdn
        placeholder
        title
      }
      eop_body
      eop_body_images {
        cdn
        placeholder
        title
      }
      seo {
        title
        description
        tracking
        image {
          cdn
        }
        schema_template
        schema
      }
    }
  }
`
